<template>
    <nav aria-label="breadcrumb">
        <ol
            class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
            :class="isRTL ? '' : ' me-sm-6'"
        >
            <li class="text-sm breadcrumb-item" :class="color">
                <a v-if="isRTL" :class="color" class="opacity-5 ps-2" href="#"
                    >لوحات القيادة</a
                >
                <router-link v-else :class="color" class="opacity-8" to="/">
                    Главная
                </router-link>
            </li>
            <li
                v-for="(item, index) in currentPage"
                :key="item"
                class="text-sm breadcrumb-item active"
                :class="color"
                aria-current="page"
            >
                <span v-if="currentPage.length - 1 === index">{{ item }}</span>

                <router-link
                    v-else
                    class="opacity-8"
                    :to="{
                        name: `${item}`,
                    }"
                >
                    {{ item }}
                </router-link>
            </li>
        </ol>
        <h6 class="mb-0 font-weight-bolder" :class="color">
            {{ currentPath }}
        </h6>
    </nav>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "breadcrumbs",
    props: {
        currentPage: {
            type: Array,
            required: "",
        },
        color: {
            type: String,
            default: "text-dark",
        },
    },
    computed: {
        ...mapState(["isRTL"]),
        currentPath() {
            return this.currentPage[this.currentPage.length - 1];
        },
    },
};
</script>
