import store from "@/store";

export default function (to, from, next) {
    const tokenPair = store.getters.getTokenPair;

    const tokenExist = tokenPair != null && tokenPair.access != null && tokenPair.access !== "";
    if (!tokenExist) {
        next({name: "Login"});
        return;
    }

    const decodeAccessToken = decodeToken(tokenPair.access);
    // const decodeRefreshToken = decodeToken(tokenPair.refresh);

    const isAccessTokenExpired = isTokenExpired(decodeAccessToken.exp)
    // const isRefreshTokenExpired = isTokenExpired(decodeRefreshToken.exp)

    /*if (isRefreshTokenExpired && isAccessTokenExpired) {
        next({name: "Auth"});
        return
    }

    if (!isRefreshTokenExpired && isAccessTokenExpired) {
        // refresh token
        return;
    }*/

    if (isAccessTokenExpired) {
        next({name: "Login"});
        return;
    }

    next();

    function decodeToken(token) {
        try {
            const tokenPayload = token.split(".")[1];
            const base64 = tokenPayload.replace("-", "+").replace("_", "/");
            return JSON.parse(atob(base64));
        } catch (error) {
            return null;
        }
    }

    function isTokenExpired(tokenExpireDate) {
        const currentTime = Date.now() / 1000;
        return tokenExpireDate < currentTime;
    }

}
