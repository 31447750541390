<template>
    <div
        class="input-group"
        :class="`${
            modelValue === '' ? '' : 'is-filled'
        } input-group-${variant} ${getStatus(error, success)}`"
    >
        <label :class="variant === 'static' ? '' : 'form-label'">{{
            label
        }}</label>

        <date-picker
            v-model:value="date"
            class="form-control"
            :class="getClasses(size)"
            input-class="material-date-picker-input"
            :input-attr="{
                id: `${id}`,
                name: `${name}`,
                placeholder: `${label}`,
            }"
            value-type="YYYY-MM-DD"
            format="DD.MM.YYYY"
            lang="ru"
        >
        </date-picker>
    </div>
</template>

<script>
import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import "vue-datepicker-next/locale/ru";

export default {
    name: "MaterialDatePicker",
    components: {
        DatePicker,
    },
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        variant: {
            type: String,
            default: "outline",
        },
        label: {
            type: String,
            default: "",
        },
        size: {
            type: String,
            default: "default",
        },
        success: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            date: null,
        };
    },
    emits: ["update:modelValue"],
    methods: {
        getClasses: (size) => {
            let sizeValue;

            sizeValue = size ? `form-control-${size}` : null;

            return sizeValue;
        },
        getStatus: (error, success) => {
            let isValidValue;

            if (success) {
                isValidValue = "is-valid";
            } else if (error) {
                isValidValue = "is-invalid";
            } else {
                isValidValue = null;
            }

            return isValidValue;
        },
    },
    watch: {
        date: function () {
            this.$emit("update:modelValue", this.date ?? "");
        },
        modelValue: function () {
            this.date = this.modelValue;
        },
    },
};
</script>

<style>
.material-date-picker-input {
    width: 100%;
    outline: none;
    border: none;
}
</style>
