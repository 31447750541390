import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import RTL from "../views/Rtl.vue";
import Notifications from "../views/Notifications.vue";
import Profile from "../views/Profile.vue";
import Auth from "../views/Auth.vue";
import Consumers from "../views/Consumers.vue";
import Consumer from "../views/Consumer.vue";
import SignUp from "../views/SignUp.vue";
import NotFound from "../views/NotFound.vue";

import checkToken from "../middleware/checkToken.js";
import NewConsumer from "@/views/NewConsumer.vue";

const routes = [
    {
        path: "/",
        name: "Main",
        redirect: "/consumers",
        beforeEnter: checkToken,
        title: "asd",
    },
    {
        path: "/login",
        name: "Login",
        component: Auth,
    },
    {
        path: "/logout",
        name: "Logout",
    },
    {
        path: "/consumers",
        name: "Клиенты",
        component: Consumers,
        beforeEnter: checkToken,
    },
    {
        path: "/consumers/consumer/:id",
        name: "Клиент",
        component: Consumer,
        beforeEnter: checkToken,
    },
    {
        path: "/consumers/consumer/new",
        name: "Новый клиент",
        component: NewConsumer,
        beforeEnter: checkToken,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        beforeEnter: checkToken,
    },
    {
        path: "/tables",
        name: "Tables",
        component: Tables,
        beforeEnter: checkToken,
    },
    {
        path: "/billing",
        name: "Billing",
        component: Billing,
        beforeEnter: checkToken,
    },
    {
        path: "/rtl-page",
        name: "RTL",
        component: RTL,
        beforeEnter: checkToken,
    },
    {
        path: "/notifications",
        name: "Notifications",
        component: Notifications,
        beforeEnter: checkToken,
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        beforeEnter: checkToken,
    },
    {
        path: "/sign-up",
        name: "SignUp",
        component: SignUp,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
});

export default router;
